import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetComplianceActionsSchemaContract {
  payroll_month: string;
  actions: {
    'run-payroll': {
      status:
        | 'completely_executed'
        | 'partially_executed'
        | 'finalized'
        | 'not_initiated'
        | 'pending'
        | 'processing';
      meta: {
        last_payroll_executed?: string;
        payroll_executed_employees?: string;
        payroll_executed_employees_pending?: string;
        payroll_executed_employees_total?: string;
        display_name: string;
        message?: string;
      };
    };
    'pay-pt': {
      /**
       * This interface was referenced by `undefined`'s JSON-Schema definition
       * via the `patternProperty` "^[a-zA-Z ]+$".
       */
      [k: string]: {
        status: 'in_progress' | 'not_initiated' | 'failed' | 'success' | 'paid_manually';
        meta: {
          amount?: {
            name?: string;
            value?: string;
            currency?: string;
          }[];
          supporting_doc?: {
            id?: string;
            link?: string;
          }[];
          payroll_executed_employees?: string;
          payroll_executed_employees_pending?: string;
          payroll_executed_employees_total?: string;
          due_date: string;
          display_name: string;
          message?: string;
        };
      };
    };
  };
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    payroll_month: { type: 'string' },
    actions: {
      type: 'object',
      properties: {
        'run-payroll': {
          type: 'object',
          properties: {
            status: {
              enum: [
                'completely_executed',
                'partially_executed',
                'finalized',
                'not_initiated',
                'pending',
                'processing',
              ],
            },
            meta: {
              type: 'object',
              properties: {
                last_payroll_executed: { type: 'string' },
                payroll_executed_employees: { type: 'string' },
                payroll_executed_employees_pending: { type: 'string' },
                payroll_executed_employees_total: { type: 'string' },
                display_name: { type: 'string' },
                message: { type: 'string' },
              },
              additionalProperties: false,
              required: ['display_name'],
            },
          },
          additionalProperties: false,
          required: ['status', 'meta'],
        },
        'pay-pt': {
          type: 'object',
          patternProperties: {
            '^[a-zA-Z ]+$': {
              type: 'object',
              properties: {
                status: {
                  enum: ['in_progress', 'not_initiated', 'failed', 'success', 'paid_manually'],
                },
                meta: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: { type: 'string' },
                          value: { type: 'string' },
                          currency: { type: 'string' },
                        },
                        additionalProperties: false,
                      },
                    },
                    supporting_doc: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: { id: { type: 'string' }, link: { type: 'string' } },
                        additionalProperties: false,
                      },
                    },
                    payroll_executed_employees: { type: 'string' },
                    payroll_executed_employees_pending: { type: 'string' },
                    payroll_executed_employees_total: { type: 'string' },
                    due_date: { type: 'string' },
                    display_name: { type: 'string' },
                    message: { type: 'string' },
                  },
                  additionalProperties: false,
                  required: ['display_name', 'due_date'],
                },
              },
              additionalProperties: false,
              required: ['status', 'meta'],
            },
          },
        },
      },
      additionalProperties: false,
      required: ['run-payroll', 'pay-pt'],
    },
  },
  additionalProperties: false,
  required: ['payroll_month', 'actions'],
};
