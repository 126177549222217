import GetComplianceActionsSchema, {
  GetComplianceActionsSchemaContract,
} from 'schemas/GetComplianceActionsSchema';
import InitiateComplianceActionSchema, {
  InitiateComplianceActionSchemaContract,
} from 'schemas/InitiateComplianceActionSchema';
import SubmitComplianceActionOtpSchema, {
  SubmitComplianceActionOtpSchemaContract,
} from 'schemas/SubmitComplianceActionOtpSchema';
import { getApi, postApi } from 'utils/Api';

type InitiateComplianceActionApiRequestData = {
  type: string;
  payroll_month: string;
  amount: string;
};

type SubmitComplianceActionOtpApiRequestData = {
  otp: string;
  compliance_id: string;
};

type MarkAsPaidComplianceActionApiRequestData = {
  type: string;
};

const complianceActions = {
  getComplianceActions: (payrollMonth: string) => {
    return getApi<GetComplianceActionsSchemaContract>({
      url: `/compliance/actions?payroll_month=${payrollMonth}`,
      responseSchema: GetComplianceActionsSchema,
    });
  },

  initiateComplianceAction: (params: { payrollMonth: string; type: string; amount: string }) => {
    return postApi<InitiateComplianceActionSchemaContract, InitiateComplianceActionApiRequestData>({
      url: `/compliance/initiate?payroll_month=${params.payrollMonth}`,
      responseSchema: InitiateComplianceActionSchema,
      requestData: {
        type: params.type,
        payroll_month: params.payrollMonth,
        amount: params.amount,
      },
    });
  },

  submitComplianceActionOtp: (params: { otp: string; complianceId: string }) => {
    return postApi<
      SubmitComplianceActionOtpSchemaContract,
      SubmitComplianceActionOtpApiRequestData
    >({
      url: '/compliance/otp/submit',
      responseSchema: SubmitComplianceActionOtpSchema,
      requestData: {
        otp: params.otp,
        compliance_id: params.complianceId,
      },
    });
  },

  markAsPaidComplianceAction: (params: { payrollMonth: string; type: string }) => {
    return postApi<
      SubmitComplianceActionOtpSchemaContract,
      MarkAsPaidComplianceActionApiRequestData
    >({
      url: `/compliance/mark-paid?payroll_month=${params.payrollMonth}`,
      responseSchema: SubmitComplianceActionOtpSchema,
      requestData: {
        type: params.type,
      },
    });
  },
};

export default complianceActions;
