import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface SubmitComplianceActionOtpSchemaContract {
  data?: {
    message?: string;
    [k: string]: unknown;
  };
  status?: string;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    data: { type: 'object', properties: { message: { type: 'string' } } },
    status: { type: 'string' },
  },
};
