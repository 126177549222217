import { getMonth, getYear, format, endOfMonth, addMonths } from 'date-fns';

const MONTH_MARCH = 3;

export function formateDateInDMY(date: Date) {
  return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
}

export function todaysDate() {
  let date = new Date();
  return formateDateInDMY(date);
}

export function formatStringToISO(dateString: string) {
  const date = new Date(dateString);
  const pad = (str: string, defaultPadCount: number = 2) =>
    String(str).padStart(defaultPadCount, '0');
  return `${pad('' + date.getFullYear())}-${pad('' + (date.getMonth() + 1))}-${pad(
    '' + date.getDate(),
  )}T00:00:00.000000Z`;
}

export function formatISOToLaravelTimestamp(dateString?: string) {
  if (!dateString) {
    return '';
  }

  return `${dateString.split('T')[0]}T00:00:00.000000Z`;
}

export function formatStringToYMD(dateString: string) {
  const date = new Date(dateString);
  const pad = (str: string) => String(str).padStart(2, '0');

  return `${pad('' + date.getFullYear())}-${pad('' + (date.getMonth() + 1))}-${pad(
    '' + date.getDate(),
  )}`;
}

export const getFinancialYearFromDate = (date: Date) => {
  const month = getMonth(date) + 1; // month starts from 0
  const year = getYear(date);

  if (month <= MONTH_MARCH) {
    return year - 1;
  } else {
    return year;
  }
};

export const getQuarterFromDate = (date: Date) => {
  const month = getMonth(date) + 1; // month starts from 0
  let quarter = Math.ceil(month / 3) - 1;
  const financialYear = getFinancialYearFromDate(date);

  if (quarter === 0) {
    quarter = 4;
  }

  return { quarter, financialYear };
};

export function getDateInYmdFormat(date: Date) {
  return date.toISOString().split('T')[0];
}

export function getTodayInYmdFormat() {
  return getDateInYmdFormat(new Date());
}

export const MONTHS_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getDateRangeObj = (year: number, month: number) => {
  const dateVal = new Date(year, month, 1);
  return {
    text: format(dateVal, 'MMMM, yyyy'),
    startDate: dateVal,
    endDate: endOfMonth(dateVal),
  };
};

export function getMonthsForFinancialYear(year: number) {
  const currentDate = new Date(year, 2, 1);
  let currentMonth = currentDate.getMonth();
  const financialYear = getFinancialYearFromDate(new Date());
  if (year === financialYear) {
    currentMonth = new Date().getMonth();
  }
  if (currentMonth < 3) {
    currentMonth = currentMonth + 12;
  }
  const firstSetMonths = [...MONTHS_LIST]
    .filter((month, idx) => idx > MONTH_MARCH - 1 && idx <= currentMonth)
    .map((month) => getDateRangeObj(year, MONTHS_LIST.indexOf(month)));
  const secondSetMonths = [...MONTHS_LIST]
    .filter((month, idx) => idx <= MONTH_MARCH - 1 && idx + 12 <= currentMonth)
    .map((month) => getDateRangeObj(year + 1, MONTHS_LIST.indexOf(month)));
  return [...firstSetMonths, ...secondSetMonths].reverse();
}

export function convertToOrdinal(n: number) {
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
}
export function formatDateForDisplay(lastActiveTimeStamp: string) {
  const currDate = new Date(lastActiveTimeStamp);
  const currDateToLocaleTimeString = currDate.toLocaleTimeString('en-US');
  const currDateToLocaleTimeStringSplit = currDateToLocaleTimeString.split(':');
  const hours = currDateToLocaleTimeStringSplit[0];
  const minutes = currDateToLocaleTimeStringSplit[1];
  const AMorPMString = currDateToLocaleTimeStringSplit[2].split(' ')[1];
  const currDateToString = currDate.toString();
  const splittedCurrDateString = currDateToString.split(' ');
  const ordinalDate = convertToOrdinal(parseInt(splittedCurrDateString[2]));
  let time = '';
  time += `${hours}:${minutes} ${AMorPMString.toLowerCase()}, ${ordinalDate} ${
    splittedCurrDateString[1]
  } ${splittedCurrDateString[3]}`;
  return time;
}

export function dateDiffInDays(date1: Date, date2: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const daysList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31,
];

export const dateFormats = {
  dayMonth: 'dd-MM',
  dayMonthShort: 'dd MMM',
  humanDate: 'do MMMM',
  longMonth: 'MMMM',
  dateMonthYear: 'dd MMMM yyyy',
  dateShortMonthCommaYear: 'dd MMM, yyyy', // 01 Jan, 2024
  dateMonthCommaYear: 'dd MMMM, yyyy', // 01 Jan, 2024
  dateMonthCommaYearCommaTime: 'dd MMM, yyyy, hh:mm a',
  yearMonthDate: 'yyyy-MM-dd',
  dateMonthYearWithSlash: 'dd/MM/yy',
  dateMonthFullYearWithSlash: 'dd/MM/yyyy',
  monthYear: 'MMMM yyyy', //November 2023
  monthCommaYear: 'MMMM, yyyy', // January, 2024
  // format 08/2024
  monthYearShort: 'MM/yyyy',
  monthDateYearWithTime: 'MMM dd, yyyy hh:mm a',
};

export const monthsList = MONTHS_LIST.map((e, i) => ({
  name: e,
  index: (i + 1).toString().padStart(2, '0'),
}));

export const getRunPayrollMonthList = (month: string | null) => {
  if (!month) {
    return [];
  }

  let monthList: Date[] = [];
  for (let i = -4; i < 8; i++) {
    monthList.push(addMonths(new Date(month + 'T00:00:00'), i));
  }
  return monthList;
};
