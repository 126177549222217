import slackSettings from './slackSettings';
import notifications from './notifications';
import varianceReportData from './varianceReport';
import esicRegistrationData from './esicRegistration';
import pfRegistrationData from './pfRegistration';
import analytics from './analytics';
import freshteamsSettings from './freshteamsSettings';
import companyOnboarding from './companyOnboarding';
import integration from './integration';
import camsSettings from './camsSettings';
import whatsappSettings from './whatsappSettings';
import bulkDownloads from './bulkDownloads';
import insurance from './insurance';
import otpEmail from './otpEmail';
import runPayroll from './runPayroll';
import * as flexiPartnerDetails from './flexiPartnerDetails';
import partnerSettings from './partnerSettings';
import taxDeduction from './taxDeduction';
import zoho from './zoho';
import employeeOnboarding from './employeeOnboarding';
import bulkUploads from './bulkUploads';
import jibbleSettings from './jibbleSettings';
import compliance from './compliance';
import loans from './loans';
import people from './people';
import kyc from './kyc';
import splitz from './splitz';
import onboarding from './onboarding';
import { search } from './search';
import login from './login';
import bonus from './bonus';
import flexibleBenefits from './flexibleBenefits';
import { workflowApis } from './workflows';
import knit from './knit';
import nps from './nps';
import fnf from './fnf';
import taxDeclarations from './taxDeclarations';
import * as customReport from './customReports';
import rxIntegration from './rxIntegration';
import { payrollAiAssistant } from './payrollAiAssistant';
import customPayrollCycle from './customPayrollCycle';
import apiAccess from './apiAccess';
import complianceActions from './complianceActions';

const api = {
  slackSettings,
  notifications,
  analytics,
  varianceReportData,
  esicRegistrationData,
  pfRegistrationData,
  freshteamsSettings,
  flexiPartnerDetails,
  companyOnboarding,
  integration,
  camsSettings,
  partnerSettings,
  whatsappSettings,
  zoho,
  bulkDownloads,
  taxDeduction,
  insurance,
  runPayroll,
  otpEmail,
  employeeOnboarding,
  bulkUploads,
  jibbleSettings,
  compliance,
  loans,
  people,
  kyc,
  splitz,
  onboarding,
  search,
  login,
  bonus,
  flexibleBenefits,
  workflowApis,
  knit,
  nps,
  rxIntegration,
  fnf,
  customReport,
  payrollAiAssistant,
  taxDeclarations,
  customPayrollCycle,
  apiAccess,
  complianceActions,
};

export default api;
